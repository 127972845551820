import $ from "jquery";
import {registerAttributes} from "./googleAnalytics.js";
import {getCookie} from "./checkout/utils/helpers";
import Cookies from "js-cookie";


async function setLocalStorage() {
    const djangoLanguageCookie = getCookie("django-language");
    if (djangoLanguageCookie === "en-us") {
        const localStorageName = "7e7a843cafe2e35bbc3ebcaae6e037d2.confluenceoutdoor.myshopify.com.checkoutId";
        const checkoutId_US = getCookie("checkoutId_US");
        console.log("checkoutId_US", checkoutId_US);

        if (checkoutId_US) {
            // Set the item in local storage
            const checkoutID = checkoutId_US.split(";")[0];
            if (!localStorage.getItem(localStorageName)) {
                localStorage.setItem(localStorageName, checkoutID);
            }
        }
    } else {
        const localStorageName = "d0fea237088d28423447a6900c601003.confluenceoutdoorcanada.myshopify.com.checkoutId";
        const checkoutId_CA = getCookie("checkoutId_CA");
        if (checkoutId_CA) {
            console.log("checkoutId_CA", checkoutId_CA);
            const checkoutID = checkoutId_CA.split(";")[0];
            // Set the item in local storage
            if (!localStorage.getItem(localStorageName)) {
                localStorage.setItem(localStorageName, checkoutID);
            }
        }

    }

}

async function setCheckoutCookie(value) {
    const djangoLanguageCookie = getCookie("django-language");
    // Select the header element by its id
    const headerElement = document.querySelector('body');

// Get the value of the data-main-website attribute
    let mainWebsiteDomain = headerElement.getAttribute('data-main-website');
    mainWebsiteDomain = mainWebsiteDomain.replace('www.', '').replace(/\/$/, '');
    console.log("mainWebsite", mainWebsiteDomain);
    console.log("setting checkoutId cookie");
    if (djangoLanguageCookie === "en-us") {
        Cookies.set("checkoutId_US", value, {domain: `.${mainWebsiteDomain}`, path: '/', expires: 1});
    } else {
        Cookies.set("checkoutId_CA", value, {domain: `.${mainWebsiteDomain}`, path: '/', expires: 1});
    }
}


export const shoppingCart = btn => {
    /*<![CDATA[*/

    (function () {
        var scriptURL = "https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js";
        if (window.ShopifyBuy) {
            if (window.ShopifyBuy.UI) {
                ShopifyBuyInit();
            } else {
                loadScript();
            }
        } else {
            loadScript();
        }

        function loadScript() {
            var script = document.createElement("script");
            script.async = true;
            script.src = scriptURL;
            (
                document.getElementsByTagName("head")[0] || document.getElementsByTagName("body")[0]
            ).appendChild(script);
            script.onload = ShopifyBuyInit;
        }

        function getCookie(name) {
            const cookieString = document.cookie;
            const cookies = cookieString.split(";");
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                if (cookie.startsWith(name + "=")) {
                    const value = cookie.substring(name.length + 1);
                    return decodeURIComponent(value);
                }
            }
            return null;
        }

        function getLocale(lang) {
            switch (lang) {
                case "en-us":
                    return "en-US";
                case "en-ca":
                    return "en-CA";
                case "fr-ca":
                    return "fr-CA";
            }
            return "en-US";
        }

        async function ShopifyBuyInit() {
            var client = null;
            await setLocalStorage();
            const djangoLanguageCookie = getCookie("django-language");
            if (djangoLanguageCookie === "en-us") {
                client = window.ShopifyBuy.buildClient({
                    domain: "confluenceoutdoor.myshopify.com",
                    storefrontAccessToken: "7e7a843cafe2e35bbc3ebcaae6e037d2",
                    language: "en-US",
                });
            } else if (djangoLanguageCookie === "en-ca") {
                client = window.ShopifyBuy.buildClient({
                    domain: "confluenceoutdoorcanada.myshopify.com",
                    storefrontAccessToken: "d0fea237088d28423447a6900c601003",
                    language: "en-CA",
                });
            } else {
                client = window.ShopifyBuy.buildClient({
                    domain: "confluenceoutdoorcanada.myshopify.com",
                    storefrontAccessToken: "d0fea237088d28423447a6900c601003",
                    language: "fr-FR",
                });
            }
            if (document.getElementById("product-brand")) {
                var productBrand = ""; // JSON.parse(document.getElementById('product-brand').textContent);
            }
            if (document.getElementById("product-category")) {
                var productCategory = ""; // JSON.parse(document.getElementById('product-category').textContent);
            }

            var userConfluenceId = $("#user-authentication").attr("data-user-cf-id");

            const addToCart = djangoLanguageCookie == "fr-ca" ? "Ajouter au panier" : "Add to cart";
            const outOfStock = djangoLanguageCookie == "fr-ca" ? "Épuisé en ligne" : "Out of stock";
            const checkoutButton = djangoLanguageCookie == "fr-ca" ? "Passer au paiement" : "Checkout";
            const subTotal = djangoLanguageCookie == "fr-ca" ? "Sous-total" : "Subtotal";
            const cartName = djangoLanguageCookie == "fr-ca" ? "Panier" : "Cart";
            const currency =
                djangoLanguageCookie == "en-ca" || djangoLanguageCookie == "fr-ca" ? "CAD" : "USD";
            const ui = await window.ShopifyBuy.UI.init(client);
            const cartEmpty =
                djangoLanguageCookie == "fr-ca" ? "Votre panier est vide" : "Your cart is empty";


            var options = {
                "option": {},

                "cart": {
                    events: {
                        beforeInit: function (cart) {
                            var actualOpen = cart.checkout.open;
                            cart.checkout.open = function (url) {
                                var newUrl = new URL(url);
                                console.log("newUrl", newUrl);
                                newUrl.searchParams.set("locale", getLocale(djangoLanguageCookie));
                                actualOpen.call(this, newUrl.toString());
                                window.dataLayer && window.dataLayer?.push({"event": "view_cart"});
                            };
                        },
                        afterInit: function (cart) {
                            $(".add-to-cart-loading").fadeOut();
                            /**
                             * Optional chaining because we cannot wait for cart
                             */
                            const totalCartQuantity = cart?.model?.lineItems?.reduce(
                                (acc, lineItem) => acc + lineItem.quantity,
                                0
                            );
                            $(".cart-count-text").text(totalCartQuantity);
                            if (cart.model && $("#user-authentication").attr("data-user-record-id")) {
                                var inp = {
                                    "target": {"value": $("#user-authentication").attr("data-user-record-id")},
                                };
                                cart.setNote(inp);
                            }
                        },
                        updateItemQuantity: function (cart) {
                            let initialQuantity = 0;
                            let retryCount = 0;
                            const maxRetries = 10;

                            function getCurrentQuantity() {
                                let totalQuantity = 0;
                                if (cart.lineItemCache) {
                                    totalQuantity = cart.lineItemCache.reduce(
                                        (acc, lineItem) => acc + lineItem.quantity,
                                        0
                                    );
                                }
                                return totalQuantity;
                            }

                            initialQuantity = getCurrentQuantity();

                            function retryFetchQuantity() {
                                let currentQuantity = getCurrentQuantity();
                                if (currentQuantity !== initialQuantity || retryCount >= maxRetries) {
                                    $(".cart-count-text").text(currentQuantity);
                                } else {
                                    retryCount++;
                                    setTimeout(retryFetchQuantity, 100);
                                }
                            }

                            retryFetchQuantity();
                            if (cart.model && $("#user-authentication").attr("data-user-record-id")) {
                                var inp = {
                                    "target": {"value": $("#user-authentication").attr("data-user-record-id")},
                                };
                                cart.setNote(inp);
                            }
                        },
                        afterRender: async function (cart) {
                            if (cart.model && $("#user-authentication").attr("data-user-record-id")) {
                                var inp = {
                                    "target": {"value": $("#user-authentication").attr("data-user-record-id")},
                                };
                                cart.setNote(inp);
                            }
                            if (cart.model) {
                                await setCheckoutCookie(cart.model?.id);
                            }

                        }
                    },

                    "styles": {
                        "button": {
                            "font-family": "Roboto, sans-serif",
                            "font-size": "16px",
                            "padding-top": "16px",
                            "padding-bottom": "16px",
                            ":hover": {
                                "background-color": "#000",
                            },
                            "background-color": "#000",
                            ":focus": {
                                "background-color": "#000",
                            },
                            "border-radius": "0px",
                        },
                        "note": {
                            "display": "none",
                        },
                    },
                    "text": {
                        "title": cartName,
                        "total": subTotal,
                        "empty": cartEmpty,
                        "noteDescription": $("#user-authentication").attr("data-user-record-id"),
                        "noteTextArea": $("#user-authentication").attr("data-user-record-id"),
                        "button": checkoutButton,
                    },
                    "contents": {
                        "note": "test",
                        "noteTextArea": $("#user-authentication").attr("data-user-record-id"),
                        //"noteDescription": $('#user-authentication').attr('data-user-record-id'),
                    },
                    "popup": false,
                    "googleFonts": ["Roboto"],
                },
                "toggle": {
                    DOMEvents: {
                        /*eslint-disable */
                        "click": function (evt, target) {
                            ui.openCart();
                            registerAttributes({
                                "event_category": "Open cart",
                                "event_label": "view_cart",
                                "user-id": userConfluenceId,
                            });
                        },
                    },
                    "sticky": false,
                    "styles": {
                        "toggle": {
                            "display": "none",
                        },
                        "count": {
                            "display": "none",
                        },
                    },
                    "googleFonts": ["Roboto"],
                },
            };

            options["product"] = {
                events: {
                    addVariantToCart: function (product) {
                        var quantity = 0;
                        const currentCartQuantityText = $(".cart-count-text").eq(0).text();
                        const currentCartQuantity = parseInt(currentCartQuantityText, 10);
                        const selectedQuantity = parseInt(product.selectedQuantity, 10);
                        if (!isNaN(currentCartQuantity) && !isNaN(selectedQuantity)) {
                            typeof currentCartQuantity === "number" && typeof selectedQuantity === "number";
                            const total = currentCartQuantity + selectedQuantity;
                            $(".cart-count-text").text(total);
                        }

                        if (product.cart.lineItemCache[0]) {
                            quantity = product.cart.lineItemCache[0].quantity += product.selectedQuantity;
                        } else {
                            quantity = product.selectedQuantity;
                        }
                        const addToCart = {
                            "event_label": "add_to_cart",
                            "user_id": 'userConfluenceId',
                            "currency": currency,
                            "value": product.model.variants[0].price.amount,
                            "items": [
                                {
                                    "id": product.model.variants[0].sku,
                                    "name": product.model.title,
                                    "category": $(".product-sku").attr("data-product-category"),
                                    "brand": $(".product-sku").attr("data-brand"),
                                    "price": product.model.variants[0].price.amount,
                                    "quantity": product.selectedQuantity,
                                    "discount": 0, // product.cart.model.discountApplications
                                },
                            ],
                        };

                        window.dataLayer && window.dataLayer?.push({"event": "add_to_cart", ...addToCart});
                        // registerAttributes(addToCart);
                    },
                    afterInit: function (product) {
                        const viewItem = {
                            "event_category": "View product details page",
                            "event_label": "view_item",
                            "user-id": userConfluenceId,
                            "item_id": product.model.variants[0].sku,
                            "item_name": product.model.title,
                            "item_category": productCategory,
                            "currency": currency,
                            "item_brand": productBrand,
                            "price": product.model.variants[0].price,
                            "quantity": product.cart.lineItemCache[0].quantity || 0,
                            "discount": null, //product.cart.model.discounts,
                        };
                        registerAttributes(viewItem);
                    }
                },
                "styles": {
                    "product": {
                        "@media (min-width: 601px)": {
                            "max-width": "calc(25% - 20px)",
                            "margin-left": "45px",
                            "margin-bottom": "50px",
                        },
                        "text-align": "left",
                    },
                    "button": {
                        "font-family": "Roboto, sans-serif",
                        "padding": "16px 0px",
                        "width": "280px",
                        ":hover": {
                            "background-color": "#2b2b2b",
                        },
                        "background-color": "#000",
                        "background-image": "url('{% static 'img/icons/add_shopping_cart.svg' %}')",
                        "background-repeat": " no-repeat",
                        "background-position-y": "center",
                        "background-position-x": "25%",
                        "padding-left": "" /* adjust as needed */,
                        ":focus": {
                            "background-color": "#343344",
                        },
                        "border-radius": "0",
                        "font-size": "18px",
                        "font-weight": "600",
                        "margin": "0 auto",
                    },
                    "quantityInput": {
                        "font-size": "16px",
                        "padding-top": "16px",
                        "padding-bottom": "16px",
                    },
                },
                "contents": {
                    "img": false,
                    "title": false,
                    "price": false,
                },
                "text": {
                    "button": addToCart,
                    "outOfStock": outOfStock,
                },
                "googleFonts": ["Roboto"],
            };
            options["productSet"] = {
                "styles": {
                    "products": {
                        "@media (min-width: 601px)": {
                            "margin-left": "-20px",
                        },
                    },
                },
            };
            options["modalProduct"] = {
                "contents": {
                    "img": false,
                    "imgWithCarousel": true,
                    "button": false,
                    "buttonWithQuantity": true,
                },
                "styles": {
                    "product": {
                        "@media (min-width: 601px)": {
                            "max-width": "100%",
                            "margin-left": "0px",
                            "margin-bottom": "0px",
                        },
                    },
                    "button": {
                        "font-family": "Roboto, sans-serif",
                        "font-size": "16px",
                        "padding-top": "16px",
                        "padding-bottom": "16px",
                        ":hover": {
                            "background-color": "#2b2b2b",
                        },
                        "background-color": "#191919",
                        ":focus": {
                            "background-color": "#2b2b2b",
                        },
                        "border-radius": "0px",
                        "padding-left": "97px",
                        "padding-right": "97px",
                    },
                    "quantityInput": {
                        "font-size": "16px",
                        "padding-top": "16px",
                        "padding-bottom": "16px",
                    },
                },
                "googleFonts": ["Roboto"],
                "text": {
                    "button": addToCart,
                },
            };

            const initShoppingCart = () => {
                if (btn) {
                    ui.createComponent("product", {
                        id: btn.attr("data-product-id"),
                        node: btn[0],
                        moneyFormat: "%24%7B%7Bamount%7D%7D",
                        toggles: [{node: document.getElementById("login-nav-cart")}],
                        options: options,
                    });
                } else if ($(".add-to-cart-btn").length > 0) {
                    $(".add-to-cart-btn")
                        .toArray()
                        .forEach(element => {
                            ui.createComponent("product", {
                                id: $(element).attr("data-product-id"),
                                node: element,
                                moneyFormat: "%24%7B%7Bamount%7D%7D",
                                toggles: [{node: document.getElementById("login-nav-cart")}],
                                options: options,
                            });
                        });
                } else {
                    ui.createComponent("product", {
                        moneyFormat: "%24%7B%7Bamount%7D%7D",
                        toggles: [{node: document.getElementById("login-nav-cart")}],
                        options: options,
                    });
                }
            };

            $(document).ready(function () {
                // Init shopping cart
                initShoppingCart();
                $(".shopify-cart-btn").on("click", function () {
                    event.stopPropagation();
                    ui.openCart();
                });
            });
        }
    })();
    /*]]>*/
};
