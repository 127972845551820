import $ from "jquery";

export const footer = () => {
  $(".footer-column:nth-child(1)").on("click", () => {
    $(".footer-column:nth-child(1) ul").toggleClass("footer-content-mobile");
    $(".footer-column:nth-child(1) .footer-arrow-button").toggleClass("icon-flip");
  });
  $(".footer-column:nth-child(2)").on("click", () => {
    $(".footer-column:nth-child(2) ul").toggleClass("footer-content-mobile");
    $(".footer-column:nth-child(2) .footer-arrow-button").toggleClass("icon-flip");
  });
  $(".footer-column:nth-child(3)").on("click", () => {
    $(".footer-column:nth-child(3) ul").toggleClass("footer-content-mobile");
    $(".footer-column:nth-child(3) .footer-arrow-button").toggleClass("icon-flip");
  });

};
