import $ from "jquery";

import html2pdf from "html2pdf.js";

export const htmlToPdf = () => {
  $(document).ready(function () {
    const exportButton = $("#save-pdf");

    let fileName = $(".order-number__text").text();

    exportButton.on("click", function () {
      const pdfElement = document.getElementById("pdf-content");

      var opt = {
        margin: 0, // Adjust the margin to allow more space for content
        filename: "order " + fileName + ".pdf",
        image: { type: "jpeg", quality: 0.95 },
        html2canvas: { scale: 9,useCORS: true },
        jsPDF: { unit: "mm", format: "a3", orientation: "portrait" }, // Adjust format and orientation as needed
      };

      // Create a configuration object with the custom filename

      html2pdf().from(pdfElement).set(opt).save();
    });
  });
};
