/* eslint-disable */
import $ from "jquery";
import SlimSelect from "slim-select";
export const ProductRegistration = () => {
    let searchParams = new URLSearchParams(window.location.search);
    let scrollEventListenerAttached = false;
    let selectProducts;
    let currentPage;
    let endOfOptions;
    let searchByzep = false;
    const zepAjaxUrl = $('#zep_ajax_url').val();
    const brandAjaxUrl = $('#brand_ajax_url').val();
    selectProducts = new SlimSelect({
        select: '#product-for-registration',
        settings: {
            showSearch: true,
            allowDeselect: true,
        },
        events: {
            search: (searchTerm) => {
                return new Promise((resolve) => {
                    let brand = $('.brand-for-product-registration').val();
              
                    fetch(`${brandAjaxUrl}?brand=${brand}&search=${searchTerm}`,{
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then((response) => response.json())
                       .then((data) => {
                        const results = transformResults (data.results);
                        resolve(results);
                       })
                });
            }
        }
    });
    const defaultOption = [{
        text: 'Select a product',
        value: '',
        class: 'option-1-products'
    }];



    const loader = (status, button) => {
        button.prop('disabled', status);
        status ? $('#loading-spinner').removeClass('d-none') : $('#loading-spinner').addClass('d-none');
    };

    function initSelect() {
        currentPage = 2;
        endOfOptions = false;
    }
    
    const getProduct = (zepNumber, element) => {
        loader(true, element);
        $.ajax({
            type: "GET",
            url: `${zepAjaxUrl}?zep_number=${zepNumber}`,
            success: (data) => {
                loader(false, element);
                $('#id_category').val(data.category__name);
                $('#id_product_id').val(data.id);
                $('.brand-for-product-registration option')
                    .removeAttr('selected')
                    .filter(`[value=${data.brands__id}]`)
                    .attr('selected', true);
                if (data.model_name) {
                    $('#id_model_name').val(data.model_name);
                    $('#id_model').val(data.model_name).focus();
                } else {
                    $('#id_model_name').val(data.description);
                }
                searchByzep = true;
            },
            error: (error) => {
                loader(false, element);
                let errorMessage = $.parseJSON(error.responseText);
                $("#error-message").show();
                $("#error-message").text(errorMessage.message);
            }
        });
    };

    $("#get-product").on('click', () => {
        const zepNumber = $('#id_zep').val();
        $("#error-message").hide();
        getProduct(zepNumber, $("#get-product"));
    });

    if (searchParams.has('zep_number')) {
        const zepNumber = searchParams.get('zep_number');
        $('#id_zep').val(zepNumber);
        getProduct(zepNumber, $("#get-product"));
    }
   

    function addOptionsToSelect(selectId, arrayOfObjects) {
        let selectElement = $(selectId);
        // Loop through the array of objects
        arrayOfObjects.forEach(function(obj) {
            // Create an option element
            var option = document.createElement('option');
            
            // Set the value of the option
            // Assuming obj has a property named 'id'
    
            // Set the text of the option
            if (obj.title) {
                option.value = obj.title;
                option.textContent = obj.title;
            } else if (obj.short_title) {
                option.value = obj.short_title;
                option.textContent = obj.short_title;
            } else {
                option.value = ''; // Or any default value if neither title nor short_title exists
                option.textContent = ''; // Or any default text if neither title nor short_title exists
            }
            
            // Append the option to the select element
            selectElement.append(option);
        });


    }
    

    function transformResults (results) {
        let transformedResults = results.map(function(result) {
            return {
                text: result.title ? result.title : result.short_title,
                value: result.title ? result.title : result.short_title,
                data: {
                    category: result.category_info.name,
                    product_id: result.id,
                    model_name: result.model_name,
                }
            }
        });

        return transformedResults;
    }

    function loadMoreOptions() {
        let brand = $('.brand-for-product-registration').val();
        if (endOfOptions) {
            console.log("Reached end of options.");
            return;
        }
    
        // Make API call to load more options
        $.ajax({
            type: "GET",
            url: `${brandAjaxUrl}?brand=${brand}&page=${currentPage}`,
            success: (data) => {
                addOptionsToSelect('#product-for-registration', data.results);
                currentPage += 1;
                const prevData = selectProducts.getData();
                let currentData = transformResults(data.results);
                let newData = prevData.concat(currentData);
                selectProducts.setData(newData);
                if (data.next === null) {
                    endOfOptions = true;
                }
            },
            error: function( error) {
                console.error('Error:', error);
            }
        });

    }

    // fill category input

    $('#product-for-registration').on('change', () => {
        let selectElement = document.querySelector('#product-for-registration');
        let selectedOption = selectElement.options[selectElement.selectedIndex];

        $('#id_model_name').val(selectedOption.getAttribute('data-model_name'));
        $('#id_product_id').val(selectedOption.getAttribute('data-product_id'));
        $('#id_category').val(selectedOption.getAttribute('data-category'));
    });
        
    
    $(".brand-for-product-registration").on('change', () => {
        let brand = $('.brand-for-product-registration').val();
        let selectedText = $('.brand-for-product-registration option[value="' + brand + '"]').text();

        if (searchByzep == false) {
            $('.model-name-label input').val('');
            $('#id_category').val('');
        }

        searchByzep = false;

        if (selectedText.trim().toLowerCase() === 'boardworks' || selectedText.trim().toLowerCase() === 'advanced elements') {
            $('#get-product').hide();
            $('#zep-without-search').attr('type', 'text');
            $('#zep-without-search').attr('required', 'true');
            $('.model-name-label input').attr('type', 'hidden');
            $("#zep-number-without-search").show();
            $('.model-name-label').hide();
            $('#id_zep').val('');

            $.ajax({
                type: "GET",
                url: `${brandAjaxUrl}?brand=${brand}`,
                success: (data) => {
                    initSelect();
                    addOptionsToSelect('#product-for-registration', data.results);
                    $('#product-registration-form').removeAttr('hidden', true);
                    
                    const options = defaultOption.concat(transformResults(data.results));
                    selectProducts.setData(options);

                    if (!scrollEventListenerAttached) {
                    const slimSelectListArr = Array.from(document.querySelectorAll('.option-1-products'));
                    const slimSelectList = slimSelectListArr[1]?.parentElement;
                    if(!scrollEventListenerAttached) {
                        slimSelectList.addEventListener('scroll', () => {
                            // Check if at bottom of select element
                            if (slimSelectList.scrollHeight - slimSelectList.scrollTop === slimSelectList.clientHeight) {
                                loadMoreOptions();
                            }
                        });
                        scrollEventListenerAttached = true;
                    }

                }
                },
                error: function( error) {
                    console.error('Error:', error);
                    $('#id_model_name').val('');
                    $('#id_category').val('');
                    $('#product-registration-form').attr('hidden', true);
                    $('#search-button-product-registration').removeAttr('hidden', true);
                    // Clear options in the select element
                    $('#product-for-registration').children('option:not([data-placeholder="true"])').remove();
                }
            });
        } else {
            $('#search-zep').show();
            $('#id_zep').attr('type', 'text');
            $('#product-registration-form').attr('hidden', true);
            $('.model-name-label input').attr('type', 'text');
            $('.model-name-label').show();
            $("#zep-number-without-search").hide();
            $('#zep-without-search').attr('required', 'true');
            $('#zep-without-search').attr('type', 'text');
            $('#get-product').show();
        }
         
    });

};

    