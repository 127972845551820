import $ from "jquery";
//import { gtag } from 'ga-gtag';
/**
    Get States for selected country
**/
export const pushEvent = (name, dict) => {
  window.gtag("event", name, dict);
};
/* eslint-disable */
export const registerAttributes = params => {
  //dataLayer({...params});
};

function getCookie(cookieName) {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(cookieName + "=")) {
      return cookie.substring(cookieName.length + 1);
    }
  }
}

const language = getCookie("django-language");

// FORM EVENT TRIGGERING START //
// warranty_claim
const warranty_claimEvent = () => {
  window.dataLayer && window.dataLayer.push({ "event": "warranty_claim" });
};
// contact_us
const contact_usEvent = () => {
  window.dataLayer && window.dataLayer.push({ "event": "contact_us" });
};
// product_registration
const product_registrationEvent = () => {
  window.dataLayer && window.dataLayer.push({ "event": "product_registration" });
};
// email_signup
const email_signupEvent = () => {
  const data = {
    "form_name": "footer",
  };
  window.dataLayer && window.dataLayer.push({ "event": "newsletter_subscription", ...data });
};
// email_signup
const signup_newsletter_signupEvent = () => {
  const data = {
    "form_name": "account creation",
  };
  window.dataLayer && window.dataLayer.push({ "event": "newsletter_subscription", ...data });
};
// email_signup
const signup_promotion_signupEvent = () => {
  const data = {
    "form_name": "account creation",
  };
  window.dataLayer && window.dataLayer.push({ "event": "promotion_subscription", ...data });
};

// email_signup
const account_newsletter_signupEvent = () => {
  const data = {
    "form_name": "preferences",
  };
  window.dataLayer && window.dataLayer.push({ "event": "newsletter_subscription", ...data });
};

// email_signup
const account_promotion_signupEvent = () => {
  const data = {
    "form_name": "preferences",
  };
  window.dataLayer && window.dataLayer.push({ "event": "promotion_subscription", ...data });
};
// sign_up
const sign_upEvent = () => {
  window.dataLayer && window.dataLayer.push({ "event": "sign_up" });
};
// log_in
const log_inEvent = () => {
  window.dataLayer && window.dataLayer.push({ "event": "login" });
};
// custom_form_submit
const custom_form_submitEvent = () => {
  const data = {
    "form_name": $(".custom-form-submitted").attr("data-form-name"),
    "button_label": language == "fr-ca" ? `Soumettre` : `Submit`,
  };
  window.dataLayer && window.dataLayer.push({ "event": "custom_form_submit", ...data });
};

// FORM EVENT TRIGGERING END //

// ECOMMERCE EVENT TRIGGERING START //

const item_listEvent = () => {
  const pageName = $("body").attr("data-pagetype");
  const data = {
    "item_list_id": pageName,
    "item_list_name": pageName,
  };
  window.dataLayer && window.dataLayer.push({ "event": "view_item_list", ...data });
};

const view_itemEvent = () => {
  if($('.msrp-price-promo').length > 0){
    var price = $('.msrp-price-promo').text();
  }
  else{
    var price = $('.msrp-price').text();
  }
  const data = {
    'currency': $(".product-sku").attr("data-currency"),
    "value": price,
    "items":[
      {
      "id": $(".product-sku").attr("data-sku"),
      "name": $(".product-title").text(),
      "brand": $(".product-sku").attr("data-brand"),
      "category": $(".product-sku").attr("data-product-category"),
      "price": price,
      "quantity": 1,
      "variant": "",
      }
    ],
  };
  window.dataLayer && window.dataLayer.push({ "event": "view_item", ...data });
};

const product_clickEvent = (e, $this) => {
  e.preventDefault();
  const productInfos = {
    "currency": $this.attr("data-product-currency"),
    "value": $this.attr("data-product-price"),
    "items": [
      {
      "id": $this.attr("data-product-sku"),
      "name": $this.attr("data-product-name"),
      "brand": $this.attr("data-product-brand"),
      "category": $this.attr("data-product-category"),
      "price": $this.attr("data-product-price"),
      "currency": $this.attr("data-product-currency"),
      "discount": $this.attr("data-product-discount"),
      } 
    ],
  };
  const productCardObject = {
    "event_category": "Product card clicked",
    "event_label": "select_item",
    "product_list_name": $("body").attr("data-pagetype"),
    ...productInfos,
  };
  //window.gtag('event', 'product_card', productCardObject);
  if (window.dataLayer) {
    window.dataLayer && window.dataLayer.push({ "event": "select_item", ...productCardObject });
  }
  window.location.href = $this.attr("href");
};

const external_storeEvent = (e, $this) => {
  const store = $this.attr("data-site-label");

  const storeLinkInfos = {
    "product_id": $this.attr("data-product-sku"),
    "product_name": $this.attr("data-product-name"),
    "product_brand": $this.attr("data-product-brand"),
    "product_category": $this.attr("data-product-category"),
  };
  window.dataLayer &&
    window.dataLayer.push({ "event": `${store}_outbound_click`, ...storeLinkInfos });
};

const locally_locator_viewEvent = () => {
  if($('.msrp-price-promo').length > 0){
    var price = $('.msrp-price-promo').text();
  }
  else{
    var price = $('.msrp-price').text();
  }
  const storeLinkInfos = {
    "currency": $(".product-sku").attr("data-currency"),
    "value": price,
    'items': [
      {
      "id": $(".product-sku").attr("data-sku"),
      "name": $(".product-title").text(),
      "category": $(".product-sku").attr("data-product-category"),
      "brand": $(".product-sku").attr("data-brand"),
      "price": price,
      "discount": 0,
      "UPC": "",
    }
  ]
};
  window.dataLayer && window.dataLayer.push({ "event": `locally_locator_view`, ...storeLinkInfos });
};

const eventMap = {
  warranty_claimEvent,
  contact_usEvent,
  product_registrationEvent,
  email_signupEvent,
  signup_newsletter_signupEvent,
  signup_promotion_signupEvent,
  account_newsletter_signupEvent,
  account_promotion_signupEvent,
  sign_upEvent,
  log_inEvent,
  custom_form_submitEvent,
};
const formSubmissionEvents = () => {
  const classes = [
    { "classname": "contact-form-success", "events": ["contact_usEvent"] },
    { "classname": "warranty-form-success", "events": ["warranty_claimEvent"] },
    { "classname": "newsletter-form-success", "events": ["email_signupEvent"] },
    {
      "classname": "registration-form-newsletter-subscription",
      "events": ["signup_newsletter_signupEvent"],
    },
    {
      "classname": "registration-form-promotion-subscription",
      "events": ["signup_promotion_signupEvent"],
    },
    { "classname": "product-registered", "events": ["product_registrationEvent"] },
    { "classname": "account-creation-success", "events": ["sign_upEvent"] },
    { "classname": "login-form-success", "events": ["log_inEvent"] },
    { "classname": "preferences-form-success", "events": [] },
    {
      "classname": "profile-form-newsletter-subscription",
      "events": ["account_newsletter_signupEvent"],
    },
    {
      "classname": "profile-form-promotion-subscription",
      "events": ["account_promotion_signupEvent"],
    },
    { "classname": "custom-form-submitted", "events": ["custom_form_submitEvent"] },
  ];

  classes.forEach(cl => {
    const is_submitted = $("." + cl.classname).length > 0;
    if (is_submitted) {
      cl.events.forEach(fn => {
        eventMap[fn]();
      });
    }
  });
};

const events = {
  "global": {
    initDatalayer: () => {
      window.dataLayer && window.dataLayer.push(events.global.datalayer);
    },
    initEvents: () => {
      // $(document).on('click', '.store__info__details', function (e) {
      //     console.log('SALUTUUUUTUTUT');
      // });
      formSubmissionEvents();
      $(".product-card").on("click", function (e) {
        const $this = $(this);
        console.log("product-card clicked", $this);
        product_clickEvent(e, $this);
      });
      //$(document).on('.product-card', 'click', product_clickEvent);

      // -- If an item list is part of the page :
      if ($(".product_card").length) {
        item_listEvent();
      }
    },
    datalayer: {
      "user_id": $("#user-authentication").attr("data-user-cf-id"),
      "page_name": $("body").attr("data-pagetype"),
    },
  },
  "Home": {
    initEvents: () => {
      console.log("Init Home");
    },
    initDatalayer: () => {
      window.dataLayer && window.dataLayer.push(events.Home.datalayer);
    },
    datalayer: {},
  },
  "Products": {
    initEvents: () => {
      console.log("Init Home");
    },
    initDatalayer: () => {
      window.dataLayer && window.dataLayer.push(events.Products.datalayer);
    },
    datalayer: {},
  },
  "product": {
    initDatalayer: () => {},
    initEvents: () => {
      // push view_item
      view_itemEvent();
      // amazon and walmart events
      $(".store-link").on("click", function (e) {
        external_storeEvent(e, $(this));
      });
      // amazon and walmart events
      $(document).on("click", ".lcly-primary-trigger", function (e) {
        locally_locator_viewEvent(e, $(this));
      });
    },
  },
};

export const initGoogleAnalytics = () => {
  $(document).ready(function () {
    events.global.initEvents();
    events.global.initDatalayer();

    const pageName = $("body").attr("data-pagetype");

    if (pageName && events[pageName]) {
      events[pageName].initDatalayer();
      events[pageName].initEvents();
    }
    //const gtagId= ($('#tag-id').attr('data-tag-id'));
    // var elem = document.getElementById('user-authentication');
    // if (elem) {
    //     var confluence_id = elem.getAttribute('data-user-cf-id');
    //     if (confluence_id && confluence_id != '') {
    //         gtag('config', gtagId, {
    //         'user_id': confluence_id
    //         });
    //     }
    // }
    //const pageName = ($('body').attr('data-pagetype'));
    // const userConfluenceId = ($('#user-authentication').attr('data-user-cf-id'));
    //
    // //const productCard = $(".product-card");
    // //const storeLink = $(".store-link");
    // //const storeLinkEvent = storeLink.attr('data-site-label');
    //
    // const contactForm = $(".contact-form-success");
    // const warrantyForm = $(".warranty-form-success");
    // const newsletterForm = $(".newsletter-form-success");
    // const productRegistrationForm = $(".product-registered");
    // const accountCreationForm = $(".account-creation-success");
    // const loginForm = $(".login-form-success");
    // const profileForm = $(".preferences-form-success");
    //
    // const productRegistrationNewsletterSubscription = $(".accept-comms");
    // const profileNewsletterSubscription = $(".profile-form-newsletter-subscription");
    // const profilePromotionSubscription = $(".profile-form-promotion-subscription");

    // if(document.getElementById('custom-form-title')){
    //     const customFormName = JSON.parse(document.getElementById('custom-form-title').textContent);
    //     const customForm = $(`.${customFormName}`);
    //     if (customForm.length){
    //
    //         const customFormObject = {
    //             'event_category':`${customFormName} Form submitted`,
    //             'event_label': `${customFormName} Form`,
    //             'form_name' : `${customFormName} Form`,
    //             'user_id': userConfluenceId,
    //             'button_text': (language == 'fr-ca') ? `Soumettre ${customFormName} ` :  `Submit ${customFormName} `,
    //         };
    //        registerAttributes(customFormObject);
    //     }
    // }
    //
    // if (contactForm.length) {
    //     const contactFormObject = {
    //     'event_category':'Contact form submitted',
    //     'event_label':'Contact Form',
    //     'form_name' :'contact-form',
    //     'user_id': userConfluenceId
    //     };
    //     registerAttributes(contactFormObject);
    // }
    //
    // if (warrantyForm.length) {
    //     const warrantyFormObject = {
    //     'event_category':'Warranty form submitted',
    //     'event_label': 'Warranty Form',
    //     'form_name' :'warranty-form',
    //     'user_id': userConfluenceId,
    //     };
    //     registerAttributes(warrantyFormObject);
    // }
    //
    // if(newsletterForm.length){
    //     const newsletterFormObject = {
    //         'event_category':'Newsletter form submitted',
    //         'event_label': 'Newsletter Form',
    //         'form_name' : 'newsletter-form',
    //         'user_id': userConfluenceId,
    //         'button_text': (language == 'fr-ca') ? "S'inscrire a la newslettter" : "Submit to newsletter",
    //     };
    //     registerAttributes(newsletterFormObject);
    // }
    //
    // if(productRegistrationForm.length) {
    //
    //     const productRegistrationFormObject = {
    //         'event_category':'Product registration form submitted',
    //          'event_label': 'product_registration',
    //          'form_name' : 'product-registration',
    //          'user_id': userConfluenceId,
    //     };
    //     registerAttributes(productRegistrationFormObject);
    //     if (productRegistrationNewsletterSubscription.length){
    //
    //         const newsletterFormObject = {
    //             'event_category':'Newsletter checkbox clicked from product registration form',
    //             'event_label': 'email_signup',
    //             'user_id': userConfluenceId,
    //         };
    //         registerAttributes(newsletterFormObject);
    //     }
    // }
    //
    // if (accountCreationForm.length){
    //
    //    const accountCreationFormObject = {
    //         'event_category':'Registration form submitted',
    //         'event_label': 'sign_up',
    //         'form_name' : 'registration-form',
    //         'user_id': userConfluenceId,
    //    };
    //    registerAttributes(accountCreationFormObject);
    // }
    //
    // if (loginForm.length){
    //
    //     const loginFormObject = {
    //         'event_category':'Login form submitted',
    //         'event_label': 'log_in',
    //         'form_name' : 'login-form',
    //         'user_id': userConfluenceId,
    //         'button_text': (language == 'fr-ca') ? "S'identifier" : "Sign In",
    //
    //     };
    //     registerAttributes(loginFormObject);
    // }
    //
    // if (profileForm.length){
    //     console.log('here');
    //     if (profileNewsletterSubscription.length){
    //         console.log('test');
    //         const newsletterFormObject = {
    //             'event_category':'Newsletter checkbox clicked from profile form',
    //             'event_label': 'email_signup',
    //             'user_id': userConfluenceId,
    //
    //         };
    //         registerAttributes(newsletterFormObject);
    //     }
    //     if (profilePromotionSubscription.length){
    //         const promotionFormObject = {
    //             'event_category':'Promotion checkbox clicked from profile form',
    //             'event_label': 'email_signup',
    //             'user_id': userConfluenceId,
    //
    //         };
    //         registerAttributes(promotionFormObject);
    //     }
    // }
  });
};
