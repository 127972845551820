import $ from "jquery";

export const brands = () => {
  $(document).ready(function () {
    const brandCard = $(".brand-card-col");
    const cardNumber = brandCard.children().length / 2;
    const cardWidth = 100 / cardNumber + "vw";
    const cardWidthDouble = 45 + "vw";
    brandCard.css("width", cardWidth);
    brandCard.hover(
      function () {
        $(this).css("width", cardWidthDouble);
        $(this).find(".brand-card-logo img").css("left", "-150px");
      },
      function () {
        $(this).css("width", cardWidth);
        $(this).find(".brand-card-logo img").css("left", 0);
      }
    );

  });
};
