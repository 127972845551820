import $ from "jquery";
import Swiper from "swiper/bundle";

export const SwatchCarousel = () => {
    $(document).ready(function () {
        new Swiper(".swatch-colors-swiper", { 
            slidesPerView: "auto",
            spaceBetween: 6,
        });
    });
};
