import $ from "jquery";

export const header = () => {
  $(document).ready(function () {
    $(".header-brand-logo-toggle").on("click", () => {
      $(".header-brand-logo").toggleClass("brand-logo-display");
      $(".brand-logo-down-arrow").toggleClass("icon-flip");
    });
    $(".header-message-close").on("click", () => {
      $(".header-message-box").hide();
    });

    // when window size change changes change the menu style
    function adjustFilterVersion() {
      $(window).width() > 992 && $(".header-brand-logo").removeClass("brand-logo-display");
    }
    $(window).on("resize", function () {
      adjustFilterVersion();
    });

    //onclick outside the menu closes it
    $(document).on("click", function (event) {
      if ($(event.target).closest(".header-brand-logo").length === 0 && $(event.target).closest(".header-brand-logo-toggle").length === 0) {
        $(".header-brand-logo").removeClass("brand-logo-display");
        $(".brand-logo-down-arrow").removeClass("icon-flip");
      }
    });
  });

  $(".search-top-icon").on("click", function (e) {
    e.preventDefault();
    const formParent = $(this).parent("form");
    if (formParent.hasClass("display-input")) {
      formParent.submit();
    } else {
      formParent.addClass("display-input");
    }
  });

  const headerSearchForm = $(".header-search-form");
  $(".search-top-icon2", $(headerSearchForm)).on("click", function (e) {
    e.preventDefault();
    headerSearchForm.addClass("active");
    headerSearchForm.submit();
  });
};
