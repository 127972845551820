import $ from "jquery";

export const statsBlock = () => {
  $(document).ready(function () {
    const statCards = $(".home-stat-card");
    const statCardsContainer = $(".home-stat-cards-wrap");
    const statCardsContainerWidth = statCardsContainer.outerWidth();
    const scrollLeftStat = $(".stat-scroll-left");
    const scrollRightStat = $(".stat-scroll-right");
    let statCardOuterWidth = getStatCardOuterWidth();
    let screenWidth = $(window).width();

    let isStatsButtonActive = false;
    function getStatCardOuterWidth() {
      return $(".home-stat-card:first-child").outerWidth(true);
    }
    function getStatsTotalWidth() {
      let statCardsTotalWidth = 0;
      statCards.each(function () {
        statCardsTotalWidth += $(this).outerWidth(true);
      });

      if (statCardsTotalWidth > screenWidth) {
        scrollLeftStat.show();
        scrollRightStat.show();
      } else {
        scrollLeftStat.hide();
        scrollRightStat.hide();
      }
      return statCardsTotalWidth;
    }

    function resetStatsPosition() {
      statCardsContainer.scrollLeft(0);
    }
    function activateStatsButtons() {
      isStatsButtonActive = true;
      scrollLeftStat.on("click", () => {
        statCardsContainer.removeClass("snap-x-mandatory");
        statCardsContainer.animate({ scrollLeft: `-=${statCardOuterWidth}px` }, 500);
        setTimeout(() => {
          statCardsContainer.addClass("snap-x-mandatory");
        }, 600);
      });
      scrollRightStat.on("click", () => {
        statCardsContainer.removeClass("snap-x-mandatory");
        statCardsContainer.animate({ scrollLeft: `+=${statCardOuterWidth}px` }, 500);
        setTimeout(() => {
          statCardsContainer.addClass("snap-x-mandatory");
        }, 600);
      });
    }

    function ifStatsSlider() {
      const statsTotalWidth = getStatsTotalWidth();
      if (statCardsContainerWidth < statsTotalWidth) {
        scrollLeftStat.removeClass("d-none");
        scrollRightStat.removeClass("d-none");
        statCardsContainer.addClass("mw-100per-24px");
        !isStatsButtonActive && activateStatsButtons();
      }
      if (statCardsContainerWidth >= statsTotalWidth) {
        scrollLeftStat.addClass("d-none");
        scrollRightStat.addClass("d-none");
        statCardsContainer.removeClass("mw-100per-24px");
      }
    }

    ifStatsSlider();

    $(window).resize(function () {
      statCardOuterWidth = getStatCardOuterWidth();
      resetStatsPosition();
      ifStatsSlider();
    });
  });
};
