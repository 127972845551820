import $ from "jquery";

export const headerBrandsCarousel = () => {
  $(document).ready(function () {

    const imgNextSrc = $(".btn-wrap .next-btn").attr("src");
    const imgPrevSrc = $(".btn-wrap .prev-btn").attr("src");
    const ariaLabelNextText = $(".btn-wrap .next-btn").attr("aria-label");
    const ariaLabelPrevText = $(".btn-wrap .prev-btn").attr("aria-label");
    const altNextText = $(".btn-wrap .next-btn").attr("alt");
    const altPrevText = $(".btn-wrap .prev-btn").attr("alt");

    $(".owl-three").owlCarousel({
        autoWidth: true,
        items: 1,
        nav: false,
        navText: [
          `<img class="prev-btn" alt="${altPrevText}" aria-label="${ariaLabelPrevText}" src="${imgPrevSrc}" />`,
          `<img class="prev-btn" alt="${altNextText}" aria-label="${ariaLabelNextText}" src="${imgNextSrc}" />`,
        ],
        dots: false,
        responsive: {
          // Breakpoint from 0 up
          0: {
            margin: 8, // Adjust the margin as per your responsive design
          },
          1000: {
            margin: 16
          }

        },
    });

  });
};

