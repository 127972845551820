import $ from "jquery";
import { Modal } from "bootstrap";

export const NewsletterPopup = () => {
    const newsletterModal = new Modal($("#newsletter-popup"), {
        keyboard: false,
    });

    const acceptCookiesBtn = $("#c-p-bn");
    const refuseCookiesBtn = $("#c-s-bn");

    refuseCookiesBtn.on("click", function () {
        handleShowpopup();
    });
    acceptCookiesBtn.on("click", function () {
        handleShowpopup();
    });


    const popupCloseBtn = $(".popup-close-btn");
    const popupTimer = $("#popup-timer").val();
    const nbrPagesToVisit = parseInt( $("#nbr-pages-to-visit").val() );
    const delayToOpenPopup = 24 * 60 * 60 * 1000; // 24 hours
    const backDrop = $("#newsletter-popup"); 

    const handleClose = () => {
        const closeTime = Date.now();
        localStorage.setItem("newsletterPopupStatus", "closed");
        localStorage.setItem("closeButtonClickTime", closeTime);
        setTimeout(function () {
            newsletterModal.show();
        }, 240000);
    };

    popupCloseBtn.on("click", function () {
        handleClose();
    });

    backDrop.on("click", function () {
        handleClose();
    });
    const lastPopupClosedTime = parseInt(
        localStorage.getItem("closeButtonClickTime") || 0
    );
    const elapsedTimeSinceLastPopup = Date.now() - lastPopupClosedTime;

    const newsletterPopupStatus = localStorage.getItem("newsletterPopupStatus");

    let nbrPagesVisited = parseInt( sessionStorage.getItem("nbrPagesVisited") || 0 );


    const currentPageUrl = window.location.href;
    if (sessionStorage.getItem("lastVisitedPage") !== currentPageUrl) {
        nbrPagesVisited++;
        sessionStorage.setItem("lastVisitedPage", currentPageUrl);
        sessionStorage.setItem("nbrPagesVisited", nbrPagesVisited);
    }
    // Function to get a specific cookie by name
    function getCookie(cookieName) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(cookieName + '=')) {
                return cookie.substring(cookieName.length + 1);
            }
        }
        return null;
    }



    const handleShowpopup = () => {
        if (newsletterPopupStatus !== "closed" && getCookie('pelican_cookies_consent') !== null ) {
            if (nbrPagesToVisit > 0 && nbrPagesToVisit === nbrPagesVisited) {
                newsletterModal.show();
            } else if (+popupTimer > 0) {
                setTimeout(function () {
                    newsletterModal.show();
                }, +popupTimer * 1000);
            }
        } else {
            if (localStorage.getItem("secondPopup") !== "opened" && getCookie('pelican_cookies_consent') !== null ) {
                setTimeout(() => {
                    localStorage.setItem("secondPopup", "opened");
                    newsletterModal.show();
                }, delayToOpenPopup - elapsedTimeSinceLastPopup);
            }
        }
    };

    handleShowpopup(); 

    $("#newsletter-form-popup").on("submit", function () {
        localStorage.setItem("secondPopup", "opened");
        localStorage.setItem("newsletterPopupStatus", "closed");
    });
};
