import $ from "jquery";
import 'bootstrap';
import Collapse from 'bootstrap/js/dist/collapse';

export const collapsedBlock = () => {
    $(document).ready(function () {
        const collapsedItem = $('.accordion-item');
        collapsedItem.click(function () {
            const collapsedText = $(this).find('.accordion-collapse');
            const collapsedButton = $(this).find('.accordion-button');
            let bsCollapse = new Collapse(collapsedText, {
                toggle: false
            });
            if(collapsedText.hasClass('show')) {
                bsCollapse.hide();
                collapsedButton.addClass('collapsed');

            }
            else {
                bsCollapse.show();
                collapsedButton.removeClass('collapsed');
            }
        });
    });
};
