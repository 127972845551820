/* eslint-disable */
import cookiesLanguageSettings from "./cookiesLanguageSettings";
/**
 * @class CookieConsent
 */
class CookieConsent {
  /**
   * Creates an instance of CookieConsent.
   *
   * @memberof CookieConsent
   */

  constructor() {
    /**
     * Init cookie consent modal
     */
    this.cookiesConsentModal = initCookieConsent();
    console.log(document.getElementById("language-code")?.value)
  }

  /**
   * init the class
   */


  ready() {
    this.cookiesConsentModal.run({
      // We need set language value in base.html
      current_lang: (document.getElementById("language-code")?.value == 'en-ca' || document.getElementById("language-code")?.value == 'en-us') ? 'en' : "fr",
      autoclear_cookies: true,
      cookie_name: "pelican_cookies_consent",
      cookie_expiration: 365,
      cookie_domain: '.' + document.getElementsByTagName('body')[0].getAttribute('data-main-website').replace('www.', ''),
      page_scripts: true,
      autorun: true,
      force_consent: true,
      
      gui_options: {
        consent_modal: {
          layout: "box", // box,cloud,bar
          position: "bottom left", // bottom,middle,top + left,right,center
          transition: "slide", // zoom,slide
        },
        settings_modal: {
          layout: "box", // box,bar
          // position: 'left', // right,left (available only if bar layout selected)
          transition: "slide", // zoom,slide
        },
      },
      languages: {
        en: document.getElementById("language-code")?.value == 'en-us' ? cookiesLanguageSettings.en_us : cookiesLanguageSettings.en_ca,
        fr: cookiesLanguageSettings.fr_ca,
      },
    });
  }
}

export default new CookieConsent();
