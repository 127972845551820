import SlimSelect from "slim-select";
import $ from "jquery";
// Define a function to update state based on country
export const updateStateBasedOnCountry = () => {
    $(document).ready(function () {
        // Wrap the code in a try-catch block for error handling
        try {
            // Define the state data for countries
            const stateData = {
                CA: [
                    {text: "", value: ""},
                    {text: "Alberta", value: "Alberta"},
                    {text: "British Columbia", value: "British Columbia"},
                    {text: "Manitoba", value: "Manitoba"},
                    {text: "New Brunswick", value: "New Brunswick"},
                    {
                        text: "Newfoundland and Labrador",
                        value: "Newfoundland and Labrador",
                    },
                    {text: "Northwest Territories", value: "Northwest Territories"},
                    {text: "Nova Scotia", value: "Nova Scotia"},
                    {text: "Ontario", value: "Ontario"},
                    {text: "Prince Edward Island", value: "Prince Edward Island"},
                    {text: "Quebec", value: "Quebec"},
                    {text: "Saskatchewan", value: "Saskatchewan"},
                    {text: "Yukon", value: "Yukon"},
                ],
                US: [
                    {text: "", value: ""},
                    {text: "Alabama", value: "Alabama"},
                    {text: "Alaska", value: "Alaska"},
                    {text: "Arizona", value: "Arizona"},
                    {text: "Arkansas", value: "Arkansas"},
                    {text: "California", value: "California"},
                    {text: "Colorado", value: "Colorado"},
                    {text: "Connecticut", value: "Connecticut"},
                    {text: "Delaware", value: "Delaware"},
                    {text: "Florida", value: "Florida"},
                    {text: "Georgia", value: "Georgia"},
                    {text: "Hawaii", value: "Hawaii"},
                    {text: "Idaho", value: "Idaho"},
                    {text: "Illinois", value: "Illinois"},
                    {text: "Indiana", value: "Indiana"},
                    {text: "Iowa", value: "Iowa"},
                    {text: "Kansas", value: "Kansas"},
                    {text: "Kentucky", value: "Kentucky"},
                    {text: "Kansas", value: "Kansas"},
                    {text: "Louisiana", value: "Louisiana"},
                    {text: "Maine", value: "Maine"},
                    {text: "Maryland", value: "Maryland"},
                    {text: "Massachusetts", value: "Massachusetts"},
                    {text: "Michigan", value: "Michigan"},
                    {text: "Minnesota", value: "Minnesota"},
                    {text: "Mississippi", value: "Mississippi"},
                    {text: "Missouri", value: "Missouri"},
                    {text: "Montana", value: "Montana"},
                    {text: "Nebraska", value: "Nebraska"},
                    {text: "Nevada", value: "Nevada"},
                    {text: "Nebraska", value: "Nebraska"},
                    {text: "New Hampshire", value: "New Hampshire"},
                    {text: "New Jersey", value: "New Jersey"},
                    {text: "New Mexico", value: "New Mexico"},
                    {text: "New York", value: "New York"},
                    {text: "North Carolina", value: "North Carolina"},
                    {text: "North Dakota", value: "North Dakota"},
                    {text: "Ohio", value: "Ohio"},
                    {text: "Oklahoma", value: "Oklahoma"},
                    {text: "Oregon", value: "Oregon"},
                    {text: "Pennsylvania", value: "Pennsylvania"},
                    {text: "Rhode Island", value: "Rhode Island"},
                    {text: "South Carolina", value: "South Carolina"},
                    {text: "South Dakota", value: "South Dakota"},
                    {text: "Tennessee", value: "Tennessee"},
                    {text: "Texas", value: "Texas"},
                    {text: "Utah", value: "Utah"},
                    {text: "Vermont", value: "Vermont"},
                    {text: "Virginia", value: "Virginia"},
                    {text: "Washington", value: "Washington"},
                    {text: "West Virginia", value: "West Virginia"},
                    {text: "Wisconsin", value: "Wisconsin"},
                    {text: "Wyoming", value: "Wyoming"},
                ],
            };
            let stateVal = null;

            setTimeout(() => {
                $('[aria-label="Country"]').attr('aria-controls', 'submit');
                $('[aria-label="State/Province"]').attr('aria-controls', 'submit');
            }, 800);

            // Initialize SlimSelect for country
            let countrySlim = new SlimSelect({
                select: document.querySelector('.custom_country'),
                settings: {
                    showSearch: true,
                    allowDeselect: true,
                },
                events: {
                    afterChange: (newVal) => {
                        if (newVal.length > 0) {
                            updateStateOptions(newVal[0].value);
                            return true;
                        }
                    },
                },
            });

            // Initialize SlimSelect for state
            let state = new SlimSelect({
                select: document.querySelector('.custom_state'),
                settings: {
                    showSearch: true,
                    allowDeselect: true,
                },
            });

            // Enable SlimSelect options based on data
            const enableStateData = (data) => {
                state.enable();
                state.setData(data);
            };

            // Disable SlimSelect for state and set default option
            const disableState = () => {
                state.disable();
                state.setData([{text: "State/province", value: ""}]);
            };

            // Update state options based on the selected country
            const updateStateOptions = (selectedValue) => {
                const data = stateData[selectedValue] || [];
                data.length > 0 ? enableStateData(data) : disableState();
                state.setSelected(stateVal);
            };

            document.addEventListener("place_changed_event", (e) => {
                countrySlim.setSelected(e.detail.data.country);
                stateVal = e.detail.data.state;
            });
        } catch (error) {
            console.error("An error occurred in the script:", error);
        }
    });
};




