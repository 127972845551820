import $ from "jquery";
import Swiper from "swiper/bundle";

export const homeProductsCarousel = () => {
  $(document).ready(function () {

   new Swiper(".product-card-swiper", {
      slidesPerView: "auto",
      speed: 400,
      spaceBetween: 20,
      navigation: {
        nextEl: ".next-btn",
        prevEl: ".prev-btn",
      },
    });

  });
};
