import $ from "jquery";

export const featuresCarousel = () => {
  $(document).ready(function () {

    const imgNextSrc = $(".btn-wrap .next-btn").attr("src");
    const imgPrevSrc = $(".btn-wrap .prev-btn").attr("src");
    const ariaLabelNextText = $(".btn-wrap .next-btn").attr("aria-label");
    const ariaLabelPrevText = $(".btn-wrap .prev-btn").attr("aria-label");
    const altNextText = $(".btn-wrap .next-btn").attr("alt");
    const altPrevText = $(".btn-wrap .prev-btn").attr("alt");

    $(".owl-four").owlCarousel({
        autoWidth: true,
        items: 1,
        nav: true,
        navText: [
          `<img class="prev-btn" alt="${altPrevText}" aria-label="${ariaLabelPrevText}" src="${imgPrevSrc}" />`,
          `<img class="next-btn" alt="${altNextText}" aria-label="${ariaLabelNextText}" src="${imgNextSrc}" />`,
        ],
        dots: false,
        responsive: {
          // Breakpoint from 0 up
          0: {
            margin: 8,
          },
          700: {
            margin: 20
          }

        },
    });

  });
};

