export const LANGUAGE_FR_CODE = "fr-ca";
export const LANGUAGE_EN_CODE = "en-ca";
export const LANGUAGE_EN_US_CODE = "en-us";
export const PELICAN_CHECKOUT_COOKIES_US = "pelican_checkout_us";
export const PELICAN_CHECKOUT_COOKIES_CA = "pelican_checkout_ca";
export const PELICAN_CHECKOUT_SHIPPING_INFORMATION_CA = "pelican_checkout_shipping_information_ca";
export const PELICAN_CHECKOUT_SHIPPING_INFORMATION_US = "pelican_checkout_shipping_information_us";
export const SAME_AS_BILLING_ADDRESS = "SAME_AS_BILLING_ADDRESS";
export const NEW_SHIPPING_ADDRESS = "NEW_SHIPPING_ADDRESS";

export const ADD_TO_CART_EVENT = "add_to_cart";
export const VIEW_CART_EVENT = "view_cart";
export const PROCEED_TO_CHECKOUT_EVENT = "proceed_to_checkout";
