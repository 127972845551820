const cookiesLanguageSettings = {
    en_us: {
      consent_modal: {
        title: "This website stores cookies on your computer.",
        description:
          'These cookies are used to collect information about how you interact with our website and allow us to remember you. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
        primary_btn: {
          text: "Accept all",
          role: "accept_all",
        },
        secondary_btn: {
          text: "Reject all",
          role: "accept_necessary",
        },
      },
      settings_modal: {
        title: "Cookies settings",
        save_settings_btn: "Save preferences",
        accept_all_btn: "Accept all cookies",
        reject_all_btn: "Reject all",
        close_btn_label: "Close",
        cookie_table_headers: [
          { col1: "Name" },
          { col2: "Domain" },
          { col3: "Expiration" },
          { col4: "Description" },
        ],
        blocks: [
          {
            title: "This website stores cookies on your computer.",
            description:
              'These cookies are used to collect information about how you interact with our website and allow us to remember you. We, and our partners, use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors both on this website and other media. To find out more about the cookies we use, see our Cookie Policy <a class="cc-link" href="/en-us/cookies/">here</a>. <br> <br> If you decline, your information won’t be tracked when you visit this website. A single cookie will be used in your browser to remember your preference not to be tracked.',
          },
          {
            title: "Essential cookies",
            description:
              "Essential cookies cannot be configured as they allow us to operate the core functions of our websites properly. These include login cookies, session ID cookies and security cookies.",
            toggle: {
              value: "necessary",
              enabled: true,
              readonly: true,
            },
          },
          {
            title: "Analytical cookies",
            description:
              "To generate anonymized statistical data like how many visitors we get, how long you stay, how many times you come back and how you reach our websites!",
            toggle: {
              value: "analytics",
              enabled: false,
              readonly: false,
            },

          },

          {
            title: "Advertising cookies",
            description:
              "To track your online behaviour across devices and marketing networks. To serve you advertising that is more relevant to you. To improve advertising efficiency and re-engagement. To allow social media functionalities.",
            toggle: {
              value: "analytics",
              enabled: false,
              readonly: false,
            },

          },

        ],
      },
    },
  
    en_ca: {
      consent_modal: {
        title: "This website stores cookies on your computer.",
        description:
          'These cookies are used to collect information about how you interact with our website and allow us to remember you. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
        primary_btn: {
          text: "Accept all",
          role: "accept_all",
        },
        secondary_btn: {
          text: "Reject all",
          role: "accept_necessary",
        },
      },
      settings_modal: {
        title: "Cookies settings",
        save_settings_btn: "Save preferences",
        accept_all_btn: "Accept all cookies",
        reject_all_btn: "Reject all",
        close_btn_label: "Close",
        cookie_table_headers: [
          { col1: "Name" },
          { col2: "Domain" },
          { col3: "Expiration" },
          { col4: "Description" },
        ],
        blocks: [
          {
            title: "This website stores cookies on your computer.",
            description:
              'These cookies are used to collect information about how you interact with our website and allow us to remember you. We, and our partners, use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors both on this website and other media. To find out more about the cookies we use, see our Cookie Policy <a class="cc-link" href="/en-ca/cookies/">here</a>. <br> <br> If you decline, your information won’t be tracked when you visit this website. A single cookie will be used in your browser to remember your preference not to be tracked.',
          },
          {
            title: "Essential cookies",
            description:
              "Essential cookies cannot be configured as they allow us to operate the core functions of our websites properly. These include login cookies, session ID cookies and security cookies.",
            toggle: {
              value: "necessary",
              enabled: true,
              readonly: true,
            },
          },
          {
            title: "Analytical cookies",
            description:
              "To generate anonymized statistical data like how many visitors we get, how long you stay, how many times you come back and how you reach our websites!",
            toggle: {
              value: "analytics",
              enabled: false,
              readonly: false,
            },

          },

          {
            title: "Advertising cookies",
            description:
              "To track your online behaviour across devices and marketing networks. To serve you advertising that is more relevant to you. To improve advertising efficiency and re-engagement. To allow social media functionalities.",
            toggle: {
              value: "analytics",
              enabled: false,
              readonly: false,
            },

          },

        ],
      },
    },
  
    fr_ca: {
      consent_modal: {
        title: "Ce site Web enregistre des témoins sur votre ordinateur.",
        description:
          "Ces témoins sont utilisés pour recueillir des renseignements sur votre façon d'interagir avec notre site et ils nous permettent de nous souvenir de vous. <button type='button' data-cc='c-settings' class='cc-link'>Laissez-moi choisir</button>",
        primary_btn: {
          text: "Tout accepter",
          role: "accept_all",
        },
        secondary_btn: {
          text: "Tout rejeter",
          role: "accept_necessary",
        },
      },
      settings_modal: {
        title: "Paramètres des cookies",
        save_settings_btn: "Enregistrer les préférences",
        accept_all_btn: "Accepter tous les cookies",
        reject_all_btn: "Tout rejeter",
        close_btn_label: "Close",

        blocks: [
          {
            title: "Ce site Web enregistre des témoins sur votre ordinateur.",
            description:
              'Ces témoins sont utilisés pour recueillir des renseignements sur votre façon d’interagir avec notre site et ils nous permettent de nous souvenir de vous. Nos partenaires et nous utilisons ces renseignements pour améliorer et personnaliser votre expérience de navigation et aux fins d’analyses et de mesures concernant les utilisateurs de ce site et d’autres médias. Pour en savoir plus sur les témoins que nous utilisons, consultez notre politique en matière de témoins <a class="cc-link" href="/fr-ca/cookies/">ici</a>. <br> <br> Si vous refusez, vos renseignements ne seront pas recueillis quand vous visiterez ce site Web. Un seul témoin sera utilisé dans votre navigateur pour garder en mémoire le fait que vous préférez ne pas faire l’objet d’un suivi. ',
          },
          {
            title: "Témoins essentiels",
            description:
              "Les témoins essentiels ne peuvent pas être configurés, car ils sont nécessaires à la bonne exécution des fonctions essentielles de nos sites Web. C’est le cas des témoins liés à la connexion, aux identificateurs de session et à la sécurité.",
            toggle: {
              value: "necessary",
              enabled: true,
              readonly: true,
            },
          },
          {
            title: "Témoins analytiques",
            description:
              "Ils servent à générer des données statistiques anonymes comme le nombre de visiteurs, la longueur des visites, le nombre de visites et le moyen utilisé pour accéder à nos sites Web.",
            toggle: {
              value: "analytics",
              enabled: false,
              readonly: false,
            },

          },

          {
            title: "Témoins publicitaires",
            description:
              "Ils permettent un suivi de votre comportement en ligne sur différents appareils et canaux de commercialisation. Ils sont utilisés pour vous présenter des publicités pertinentes pour vous. Ils permettent d’améliorer l’efficacité de la publicité et le réengagement. Ils permettent l’utilisation de fonctionnalités de réseaux sociaux.",
            toggle: {
              value: "analytics",
              enabled: false,
              readonly: false,
            },

          },

        ],
      },
    },
  };
  
  export default cookiesLanguageSettings;
  