import $ from "jquery";

export const headerBrandActive = () => {
  $(document).ready(function () {
    
    const pageUrl = window.location.href;
    
    function normalizeUrl(url) {
      let normalizedUrl = new URL(url);
      return normalizedUrl.origin;
    }
    
    function areUrlsEqual(url1, url2) {
      return normalizeUrl(url1) === normalizeUrl(url2);
    }

    // // Check if the URL contains the brand segment
    function checkURL(url) {
      if ( areUrlsEqual(url, pageUrl) ) {
        return true;
      } else {
        return false;
      }
    }

    // Iterate over each 'a' tag with class 'top-header-brands__brand-logo'   
    $('.top-header-brands__brand-logo').each(function() {
      const url = $(this).attr('href');
      if (checkURL(url)) {
        $(this).find('img').css('filter', 'brightness(0)');
      }
    });
    
  });
};
