import $ from "jquery";

export const userProfileTabs = () => {
    const btnEditUserInfo = $('#btn-edit-user-info');
    const userInfoDisplay = $('#user-info-display');
    const userInfoEdit = $('#user-info-edit');

    btnEditUserInfo.on('click', function () {
        $(this).hide();
        userInfoDisplay.hide();
        userInfoEdit.show();
    });

    $('#pills-2-tab, #pills-3-tab').on('click', function () {
        btnEditUserInfo.hide();
    });

    $('#pills-1-tab').on('click', function () {
        btnEditUserInfo.show();
        userInfoDisplay.show();
        userInfoEdit.hide();
    });
};