
import $ from "jquery";

export const datePicker = () => {
  $(document).ready(function () {

    const langCode = $("#language-code").val();
    $('.date-picker').datepicker({
      format: 'yyyy-mm-dd',
     language: langCode == 'en-us' || langCode == 'en-ca' ? 'en' : 'fr',
    });
  });


};
