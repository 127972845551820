import $ from "jquery";

export const statsCarousel = () => {
  $(document).ready(function () {

    const imgNextSrc = $(".btn-wrap .next-btn").attr("src");
    const imgPrevSrc = $(".btn-wrap .prev-btn").attr("src");
    const ariaLabelNextText = $(".btn-wrap .next-btn").attr("aria-label");
    const ariaLabelPrevText = $(".btn-wrap .prev-btn").attr("aria-label");
    const altNextText = $(".btn-wrap .next-btn").attr("alt");
    const altPrevText = $(".btn-wrap .prev-btn").attr("alt");

    $(".owl-two").owlCarousel({
        autoWidth: true,
        items: 1,
        nav: true,
        navText: [
          `<img class="prev-btn" alt="${altPrevText}" aria-label="${ariaLabelPrevText}" src="${imgPrevSrc}" />`,
          `<img class="prev-btn" alt="${altNextText}" aria-label="${ariaLabelNextText}" src="${imgNextSrc}" />`,
        ],
        dots: false,
        responsive: {
          // Breakpoint from 0 up
          0: {
            margin: 40, // Adjust the margin as per your responsive design
          },
          // Breakpoint from 720 up
          720: {
            margin: 50, // Adjust the margin as per your responsive design
          },
          // Breakpoint from 1000 up
          1000: {
            margin: 150, // Adjust the margin as per your responsive design
          },
        },
    });

  });
};
