import SlimSelect from "slim-select";

import $ from "jquery";

export const slimSelectInit = () => {
  $(document).ready(function () {
    try {
      let selectElements = document.querySelectorAll(".selectElement");
      let selectMultiElements = document.querySelectorAll(
        ".selectMultiElement"
      );

      selectElements.forEach(function (element) {
        new SlimSelect({
          select: element,
          settings: {
            showSearch: false,
            allowDeselect: true,
          },
        });
      });

      selectMultiElements.forEach(function (element) {
        new SlimSelect({
          select: element,
          settings: {
            showSearch: false,
            allowDeselect: true,
          },
        });
      });
    } catch (error) {
      console.error("Error initializing SlimSelect:", error);
    }
  });
};
