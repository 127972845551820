import $ from "jquery";

export const registration = () => {
    $(document).ready(function () {
        function handleFormInput() {
            $('.login-form input').on("focus", function () {
                const input = $(this);
                const originalPlaceholder = input.attr("placeholder");
                input.attr("placeholder", "");
                input.on("blur", function () {
                    if (input.val() === "") {
                        input.attr("placeholder", originalPlaceholder);
                    }
                    input.off("blur");
                });
            });
        }
        $('.login-form').length && handleFormInput();
    });
};