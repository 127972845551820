import $ from "jquery";

export const matchHeight = () => {
  $(document).ready(function () {

    // product card height
    $('.product-card-height').jQueryEqualHeight('.product-card-body__title');
    $('.product-card-height').jQueryEqualHeight('.swatch-colors');
    $('.product-card-height').jQueryEqualHeight('.product-card-body__subtitle');
    $('.product-card-height').jQueryEqualHeight('.bottom-info');
    $('.product-card-height').jQueryEqualHeight('.card-img-top');



    // //Feartured block height
    $(".featured-blogs-title").matchHeight();
    $(".featured-blogs-excerpt").matchHeight();

    // column block height
    $(".column-block__title h2").matchHeight();
    $(".column-block__title h3").matchHeight();
    $(".column-block__title h4").matchHeight();
    $(".column-block__title p").matchHeight();

    $(".column-block-content-height").matchHeight();
    $(".column-block__text").matchHeight();



    // galery cta height
    $('.bellow-image-content .title').matchHeight();
    $('.bellow-image-content .text').matchHeight();


    //product registration

    $('.pg-profile .card-prod-model-name').matchHeight();

  });
};
