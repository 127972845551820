import $ from "jquery";

export const shareBtn = () => {
  $(document).ready(function () {
    //share btn
    const mobileLinks = document.querySelectorAll(".mobile-menu div");
    const desktopLinks = document.querySelectorAll(".desktop-menu div");
    const desktopBtn = document.querySelector("#desktop-share-btn");
    const mobileBtn = document.querySelector("#mobile-share-btn");

    if (mobileBtn) {
      mobileBtn.addEventListener("click", () => {
        mobileBtn.classList.toggle("active");
        setTimeout(() => {
          mobileLinks.forEach((link) => {
            link.classList.toggle("active");
          });
        }, 100);
      });
    }

    if (desktopBtn) {
      desktopBtn.addEventListener("click", () => {
        desktopBtn.classList.toggle("active");
        setTimeout(() => {
          desktopLinks.forEach((link) => {
            link.classList.toggle("active");
          });
        }, 100);
      });
    }
  });
};
