import $ from "jquery";
export const SearchInput = () => {
    let isOpen = false;

    $("#search-icon").on("click", function() {
        if (!isOpen) {
            $(".header-search-container").fadeIn("fast");
            $(this).addClass('active');
            $(".header-search-container input").focus();
            isOpen = true;

        } else {
            $(".header-search-container").fadeOut("fast");
            $(this).removeClass('active');
            isOpen = false;
        }
    });

    $(document).keyup(function(e) {
        if (e.key === "Escape") {
            $(".header-search-container").fadeOut("fast");
            $("#search-icon").removeClass("active");
        }
      });

    $("#search-icon-mobile").on("click", function() {
        if (!isOpen) {
            $(".mobile-header-search-container").fadeIn("fast");
            $(this).addClass('active');
            $(".mobile-header-search-container input").focus();
            isOpen = true;

        } else {
            $(".mobile-header-search-container").fadeOut("fast");
            $(this).removeClass('active');
            isOpen = false;
        }
    });
};
