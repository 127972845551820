import $ from "jquery";

export const bazaarvoiceScript = () => {
  $(document).ready(function () {
    let bv_site_id = $("#js-bv-site-id").val();
    let bv_site_env = $("#js-bv-site-env").val();
    let bv_site_language = $("#js-bv-site-language").val();

    const newBVscript = $("<script async></script>");

    newBVscript.attr(
      "src",
      `https://apps.bazaarvoice.com/deployments/pelicansport/${bv_site_id}/${bv_site_env}/${bv_site_language}/bv.js`
    );

    $("body").append(newBVscript);
  });
};
