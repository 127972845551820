import $ from "jquery";

// export const priceFormat = () => {
//     $(document).ready(function () {
//         const langCode = $("#language-code").val();
//
//         if ($(".format-price")) {
//             const pricesArr = $(".format-price").toArray();
//             console.log('pricesArr', pricesArr);
//
//             if (langCode == "fr-ca" || langCode == "en-ca") {
//                 pricesArr.forEach((price) => {
//                     const formatedPrice = new Intl.NumberFormat(langCode, {
//                         style: "currency",
//                         currency: "CAD",
//                     }).format(
//                         parseFloat(price.innerText.replace(/\$/g, "").replace(/,/g, "."))
//                     );
//                     price.innerText = formatedPrice;
//                 });
//             } else {
//                 pricesArr.forEach((price) => {
//                     const formatedPrice = new Intl.NumberFormat(langCode, {
//                         style: "currency",
//                         currency: "USD",
//                     }).format(
//                         parseFloat(price.innerText.replace(/\$/g, "").replace(/,/g, "."))
//                     );
//                     price.innerText = formatedPrice;
//                 });
//             }
//         }
//     });
// };
export const priceFormat = () => {
    $(document).ready(function () {
        const langCode = $("#language-code").val();

        if ($(".format-price").length) {
            const pricesArr = $(".format-price").toArray();

            // Define currency based on langCode
            const currency = (langCode == "fr-ca" || langCode == "en-ca") ? "CAD" : "USD";

            pricesArr.forEach((priceElement) => {
                let priceText = priceElement.innerText;

                // Remove currency symbols
                let numericPrice = priceText.replace(/[^0-9.,-]+/g, "");

                // Handle fr-ca specifically
                if (langCode === "fr-ca") {
                    // Replace comma with a period for decimal places
                    numericPrice = numericPrice.replace(/,/g, '.');
                }

                // Handle en-ca or other locales
                else {
                    // Remove any commas for thousands separators
                    numericPrice = numericPrice.replace(/,/g, '');
                }


                // Parse the numeric value
                let priceValue = parseFloat(numericPrice);

                // Format the price according to the locale and currency
                const formattedPrice = new Intl.NumberFormat(langCode, {
                    style: "currency",
                    currency: currency,
                }).format(priceValue);

                // Set the formatted price back to the element
                priceElement.innerText = formattedPrice;
            });
        }
    });
};

