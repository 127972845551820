import $ from "jquery";
import moment from "moment";

export const dateFormat = () => {
    $(document).ready(function () {
        const langCode = $("#language-code").val();

        if ($(".format-date")) {
            const datesArr = $(".format-date").toArray();

            if (langCode == "fr-ca" || langCode == "en-ca") {
                datesArr.forEach(date => {
                    let mom = moment(date.innerText).format('DD/MM/YYYY');
                    const frDateFormat = mom;
                    date.innerText = frDateFormat;
                });

            } else {
                datesArr.forEach(date => {
                    //  const frDateFormat = new Intl.DateTimeFormat('en-US',options).format(new Date(date.innerText));
                    let mom = moment(date.innerText).format('MM/DD/YYYY');
                    const enDateFormat = mom;
                    date.innerText = enDateFormat;

                });
            }
        }
    });
};
