import Cookies from "js-cookie";
import {
    LANGUAGE_EN_US_CODE,
    PELICAN_CHECKOUT_SHIPPING_INFORMATION_CA,
    PELICAN_CHECKOUT_SHIPPING_INFORMATION_US,
} from "../constants/AppConstants";

/**
 *
 * @param key
 * @param initialValue
 * @returns {any}
 */
export const getLocalStorage = (key, initialValue) => {
    try {
        const value = localStorage.getItem(key);
        return value ? JSON.parse(value) : initialValue;
    } catch (e) {
        // if error, return initial value
        return initialValue;
    }
};

/**
 *
 * @param key
 * @param value
 */
export const setLocalStorage = (key, value) => {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    } catch (e) {
        console.log("Error setting localStorage key: " + key + " to: " + value);
    }
};

/**
 * Get cookie by name
 * @param {string} cookiesName
 * @returns
 */
export const getCookie = cookiesName => {
    return Cookies.get(cookiesName);
};

/**
 * Set cookie by name
 * @param key
 * @param value
 * @param days
 */
export const setCookie = (key, value, days = 7, domain) => {
    return Cookies.set(key, value, {expires: days, domain: domain});
};

/**
 * Get current language code
 *
 * @param defaultLanguage
 * @returns {*|string}
 */
export const getCurrentLanguageCode = (defaultLanguage = "fr-ca") => {
    return document.getElementById("language-code")?.value || defaultLanguage;
};

/**
 * Check if user has already set shipping address manually
 * If shipping address contain at least one null value, means it's invalid
 *
 * @returns {boolean}
 */
export const hasAlreadySetShippingAddress = () => {
    const currentLanguage = getCurrentLanguageCode();
    const shippingAddressCookieName =
        currentLanguage === "en-us"
            ? PELICAN_CHECKOUT_SHIPPING_INFORMATION_US
            : PELICAN_CHECKOUT_SHIPPING_INFORMATION_CA;

    const address = getLocalStorage(shippingAddressCookieName);
    if (address) {
        const addressObj = JSON.parse(address);
        const requiredFields = [
            "first_name",
            "last_name",
            "country",
            "state",
            "city",
            "postal_code",
            "address_line1",
        ];
        return requiredFields.every(
            field => addressObj[field] !== undefined && addressObj[field] !== ""
        );
    }
    localStorage.removeItem(shippingAddressCookieName);
    return false;
};

/**
 * Add debounce on actions
 *
 * @param {*} func
 * @param {*} wait
 * @returns
 */
export const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

/**
 * Format address to Shopify format
 * @param address
 * @returns {{zip, country, firstName, lastName, province, city, phone, address1, company: null}}
 */
export const formatAddressToShopifyFormat = address => {
    return {
        address1: address.address_line1,
        city: address.city,
        company: null,
        country: address.country,
        firstName: address.first_name,
        lastName: address.last_name,
        phone: address.phone,
        province: address.state,
        zip: address.postal_code,
    };
};

export const normalizeAddressInformation = data => {
    const profile = data?.first_name ? data : data?.user?.profile;
    const {first_name, last_name, phone, email, country, state, city, postal_code, address_line1} =
        profile;
    return {
        first_name,
        last_name,
        phone,
        email,
        country,
        state,
        city,
        postal_code,
        address_line1,
    };
};

/**
 *
 * @param status
 * @param msg
 * @returns {string}
 */
export const setErrorMessage = (status, msg = "") => {
    const errorMessages = {
        404: "error.error_page_404",
        401: "error.error_page_401",
        403: "error.error_page_403",
        500: "Unexpected Error ):",
        422: "error.error_page_422",
        405: msg,
        409: msg,
        400: msg,
        402: msg,
    };

    return errorMessages[status] || "error.unexpected_error";
};

export const delayUpdate = ms => new Promise(resolve => setTimeout(resolve, ms));

/**
 * Pool shipping rates until they are ready
 * When updating shipping address, Shopify takes some time to update shipping rates
 * We need to poll until the shipping rates are ready
 *
 * @param client
 * @param checkoutId
 * @param maxAttempts
 * @param interval
 * @returns {Promise<*|string>}
 */
export const pollShippingRates = async (client, checkoutId, maxAttempts = 5, interval = 1000) => {
    let attempts = 0;

    const fetchShippingRates = async () => {
        const checkoutQuery = client?.graphQLClient?.query(root => {
            root.add("node", {args: {id: checkoutId}}, node => {
                node.addInlineFragmentOn("Checkout", checkout => {
                    checkout.add("availableShippingRates", availableShippingRates => {
                        availableShippingRates.add("ready");
                        availableShippingRates.add("shippingRates", {args: {}}, shippingRate => {
                            shippingRate.add("handle");
                            shippingRate.add("title");
                            shippingRate.add("price", price => {
                                price.add("amount");
                                price.add("currencyCode");
                            });
                        });
                    });
                });
            });
        });
        const response = await client?.graphQLClient?.send(checkoutQuery);
        const shippingRates = response?.data?.node?.availableShippingRates;

        if (shippingRates?.ready || attempts >= maxAttempts) {
            return shippingRates;
        } else {
            attempts++;
            await delayUpdate(interval);
            return fetchShippingRates();
        }
    };

    return fetchShippingRates();
};

/**
 * Update shipping line mutation
 *
 * @param client
 * @param checkoutId
 * @param shippingRateHandle
 * @returns {*}
 */
export const shippingLineUpdateMutation = (client, checkoutId, shippingRateHandle) => {
    return client.graphQLClient.mutation(root => {
        root.add(
            "checkoutShippingLineUpdate",
            {
                args: {
                    checkoutId: checkoutId,
                    shippingRateHandle: shippingRateHandle,
                },
            },
            checkoutShippingLineUpdate => {
                checkoutShippingLineUpdate.add("checkout", checkout => {
                    checkout.add("id");
                    checkout.add("webUrl");
                    checkout.add("totalPrice", totalPrice => {
                        totalPrice.add("amount");
                        totalPrice.add("currencyCode");
                    });
                    checkout.add("subtotalPrice", subtotalPrice => {
                        subtotalPrice.add("amount");
                        subtotalPrice.add("currencyCode");
                    });
                    checkout.add("totalTax", totalTax => {
                        totalTax.add("amount");
                        totalTax.add("currencyCode");
                    });
                    checkout.add("taxesIncluded");
                    checkout.add("shippingLine", shippingLine => {
                        shippingLine.add("handle");
                        shippingLine.add("title");
                        shippingLine.add("price", price => {
                            price.add("amount");
                            price.add("currencyCode");
                        });
                    });
                });
                checkoutShippingLineUpdate.add("checkoutUserErrors", errors => {
                    errors.add("code");
                    errors.add("field");
                    errors.add("message");
                });
            }
        );
    });
};

/**
 * Helper function to get correct format for language code
 * Example: en-us -> en-US, fr-ca -> fr-CA
 *
 * @param code
 * @returns {string}
 */
export const formatLanguageCode = code => {
    return code
        .split("-")
        .map((part, index) => (index === 0 ? part.toLowerCase() : part.toUpperCase()))
        .join("-");
};

export const formatMoney = (amount, currencyCode) => {
    const currentLanguage = getCurrentLanguageCode();
    const languageCode = formatLanguageCode(currentLanguage);
    const formattedPrice = new Intl.NumberFormat(languageCode, {
        style: "currency",
        currency: currencyCode,
    });
    return formattedPrice.format(amount);
};

/**
 * Get variant ID from shopify graphQL ID
 * @param fullId
 * @returns {string}
 */
export const extractVariantID = fullId => {
    const parts = fullId.split("/");
    return parts[parts.length - 1];
};

export const getShippingCookieName = currentLanguage =>
    currentLanguage === LANGUAGE_EN_US_CODE
        ? PELICAN_CHECKOUT_SHIPPING_INFORMATION_US
        : PELICAN_CHECKOUT_SHIPPING_INFORMATION_CA;
