import $ from "jquery";

export const hideBuyonline = () => {
  $(document).ready(function () {
  const buyOnlineTitle = $('.buy-online-title');
    setTimeout(()=>{
        const iframeAddToCart = document.querySelectorAll("iframe[name^=\"frame-product-\"]")[0];
        const amazonWalmartBlock = document.querySelector('.amazon-walmart-block');
        const bv_texts = $('.bv_averageRating_component_container .bv_text');
        
        bv_texts.each(function(){
          if($(this).text() === '0.0') {
            $(this).closest('.bv_main_container').css('opacity', '0');
          }else{
            $(this).closest('.bv_main_container').css('opacity', '1');
          }
        });

        if(iframeAddToCart) {
            let btn = iframeAddToCart.contentDocument.querySelector("button.shopify-buy__btn");
            if(!btn && !amazonWalmartBlock) {
                buyOnlineTitle.hide();
            }
        }

    },2000);

  });
};
