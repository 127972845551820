import $ from "jquery";
export const SidebarSearch = () => {
   $("#sidebar-search-icon").on("click", () => {
     localStorage.setItem("mobile-search", "clicked");
   });

   if(localStorage.getItem("mobile-search") === "clicked") {
      $("#search-icon-mobile").click();
      $(".mobile-header-search-container input").focus();
      localStorage.removeItem("mobile-search");
   }

};
