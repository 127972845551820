import $ from "jquery";

export const profile = () => {
    // const statesAjaxURL = $("#states-ajax-url").val();

    // const fetchStates = (countryCode) => {
    //     $.ajax({
    //         url: `${statesAjaxURL}?country=${countryCode}`,
    //         type: "GET",
    //         dataType: "json",
    //         success: function(data) {
    //             let  states = Object.fromEntries(data);
    //             $('select[name="state"]').empty();
    //             $.each(states, (key, value) => {
    //                 $('select[name="state"]').append('<option value="' + key + '">' + value + '</option>');
    //             });
    //         }
    //     });
    // };

    // if($('#id_country').val()) {
    //     fetchStates($('#id_country').val());
    // }

    // $('#id_country').on('change', () => {
    //     const countryCode = $('#id_country').val();
    //     if (countryCode) {
    //         fetchStates(countryCode);
    //     } else {
    //         $('select[name="state"]').empty();
    //     }
    // });


    $('#id_unsubscribe_from_all').on('click', function () {
        if($(this).is(':checked')) {
            $('#id_newsletter_subscription, #id_promotion_subscription').prop( "checked", false ).attr("disabled", true);
        } else {
            $('#id_newsletter_subscription, #id_promotion_subscription').removeAttr("disabled");
        }
    });
};