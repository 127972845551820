import $ from "jquery";

export const dropdownCheckboxes = () => {
    $(document).ready(function () {
      const langCode = $("#language-code").val();

        let CheckboxDropdown = function(el) {
            let _this = this;
            this.isOpen = false;
            this.areAllChecked = false;
            this.$el = $(el);
            this.$label = this.$el.find('.dropdown-label');
            this.$checkAll = this.$el.find('[data-toggle="check-all"]').first();
            this.$inputs = this.$el.find('[type="checkbox"]');
            
            this.onCheckBox();
            
            this.$label.on('click', function(e) {
              e.preventDefault();
              _this.toggleOpen();
            });
            
            this.$checkAll.on('click', function(e) {
              e.preventDefault();
              _this.onCheckAll();
            });
            
            this.$inputs.on('change', function() {
              _this.onCheckBox();
            });

            // Check all checkboxes by default
            _this.onCheckAll(true);
          };
          
          CheckboxDropdown.prototype.onCheckBox = function() {
            this.updateStatus();
          };
          
          CheckboxDropdown.prototype.updateStatus = function() {


            let checked = this.$el.find(':checked');
    
            this.areAllChecked = false;
            this.$checkAll.html( `${langCode == 'fr-ca' ? 'Tout cocher' : 'Check all'}`);
        
            let checkboxArray = this.$inputs.get();
            let checkedArray = checked.get();
            
            checkboxArray.forEach(function(input) {
              $(input).val(false);
            });

            checkedArray.forEach(function(input) {
              $(input).val(true);
            });

            if(checked.length <= 0) {
              langCode == 'fr-ca' ? this.$label.html('Choisissez la/les marque(s)') : this.$label.html('Choose brand(s)');
            }
            else if(checked.length === 1) {
              this.$label.html(checked.parent('label').text());
            }
            else if(checked.length === this.$inputs.length) {
              this.$label.html(`${langCode == 'fr-ca' ? 'Toutes les marques (' + (checked.length) + ')' : 'All brands selected (' + checked.length + ')'}`);
              this.areAllChecked = true;
              this.$checkAll.html( `${langCode == 'fr-ca' ? 'Tout décocher' : 'Uncheck all'}`);
            }
            else {
              const text = langCode == 'fr-ca' ? 'marques sélectionnées' : 'brands selected';
              this.$label.html(checked.length + ' ' + text);
            }
          };
          
          CheckboxDropdown.prototype.onCheckAll = function(checkAll) {
            if(!this.areAllChecked || checkAll) {
              this.areAllChecked = true;
              this.$checkAll.html( `${langCode == 'fr-ca' ? 'Tout décocher' : 'Uncheck all'}`);
              this.$inputs.prop('checked', true);
            }
            else {
              this.areAllChecked = false;
              this.$checkAll.html( `${langCode == 'fr-ca' ? 'Tout cocher' : 'Check all'}`);
              this.$inputs.prop('checked', false);
            }
            
            this.updateStatus();
          };
          
          CheckboxDropdown.prototype.toggleOpen = function(forceOpen) {
            let _this = this;
            
            if(!this.isOpen || forceOpen) {
               this.isOpen = true;
               this.$el.addClass('on');
              $(document).on('click', function(e) {
                if(!$(e.target).closest('[data-control]').length) {
                 _this.toggleOpen();
                }
              });
            }
            else {
              this.isOpen = false;
              this.$el.removeClass('on');
              $(document).off('click');
            }
          };
          
          let checkboxesDropdowns = document.querySelectorAll('[data-control="checkbox-dropdown"]');
          for(let i = 0, length = checkboxesDropdowns.length; i < length; i++) {
            new CheckboxDropdown(checkboxesDropdowns[i]);
          }

    });
};

