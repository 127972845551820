import $ from "jquery";

/**
 * Consent checkbox for all forms
 */
export const ConsentCheckbox = () => {
  $(document).ready(function () {
    const $checkboxes = $('input[id="consent-checkbox"]');

    /**
     * Disable/enable submit button based on checkbox state
     *
     * @param {*} $checkbox
     */
    const toggleSubmitState = ($checkbox) => {
      const $form = $checkbox.closest("form");
      const $submitButton = $form.find(
        'input[type="submit"], button[type="submit"]'
      );
      $submitButton.prop("disabled", !$checkbox.is(":checked"));
    };

    $checkboxes.each(function () {
      const $checkbox = $(this);

      // Just setting our initial state
      toggleSubmitState($checkbox);

      $checkbox.on("change", function () {
        toggleSubmitState($checkbox);
      });
    });

    /** Just in case */
    $("form").on("submit", function (e) {
      const $form = $(this);
      const $checkbox = $form.find('input[id="consent-checkbox"]');
      if ($checkbox.length && !$checkbox.is(":checked")) {
        e.preventDefault();
      }
    });
  });
};
