import {MDCTextField} from '@material/textfield';


import $ from "jquery";

export const textFieldInit = () => {
  $(document).ready(function () {

    const textFields = $('.mdc-text-field').toArray();
 
    textFields.forEach((element) => {
        new MDCTextField(element);
      });

    $('.login-form .mdc-text-field').addClass("mdc-text-field mdc-text-field--outlined w-100 mdc-text-field--label-floating");
    $('.login-form .mdc-notched-outline.mdc-notched-outline--upgraded').addClass("mdc-notched-outline mdc-notched-outline--upgraded mdc-notched-outline--notched");
    $('.login-form .mdc-floating-label').addClass('mdc-floating-label mdc-floating-label--float-above');

  });
};


